<template>
  <div class="detail">
    <div class="float_container">
      <div class="float_item">
        <img src="@as/personal/partner.png" alt="" />
      </div>
      <div class="float_item">
        <img src="@as/personal/forbidden.png" alt="" />
      </div>
      <div class="float_item">
        <img src="@as/personal/lanlike.png" alt="" />
      </div>
      <div class="float_item">
        <img src="@as/personal/xiazai.png" alt="" />
      </div>
    </div>
    <div class="section flex-jcbt padding-bottom-65">
      <div class="left">
        <div class="name">李晓蓓</div>
        <div class="info">
          <div class="item">28岁</div>
          <div class="item">男</div>
          <div class="item">硕士研究生</div>
          <div class="item">6年 经验</div>
          <div class="item">在职-考虑机会</div>
        </div>
        <div class="phone">18577771234</div>
      </div>
      <img src="@as/personal/personSet.png" alt="" class="right" />
    </div>
    <div class="section padding-bottom-26">
      <div class="title">职业意向</div>
      <div class="info">
        <div class="item">销售经理</div>
        <div class="item">法律行业</div>
        <div class="item">北京</div>
        <div class="item">15-18k</div>
        <div class="item">全职</div>
      </div>
    </div>
    <div class="section padding-bottom-40">
      <div class="title">工作经历</div>
      <div class="subtitle">
        <div class="name">中企动力北京第二分公司</div>
        <div class="tag">律师</div>
        <div class="time">2016.04-2020.06</div>
      </div>
      <div class="row">
        内容：1.主要负责个人网站订单管理，沟通，设计，制作工作
      </div>
      <div class="row">2.协助设计经理管理部门以及面试工作</div>
      <div class="row">3.处理部门困难客户</div>
    </div>
    <div class="section padding-bottom-34">
      <div class="title">教育经历</div>
      <div class="subtitle">
        <div class="name margin-right-90">中企动力北京第二分公司</div>
        <div class="time">2016.04-2020.06</div>
      </div>
      <div class="info" style="margin-top: 0">
        <div class="item">法律系</div>
        <div class="item">本科</div>
      </div>
    </div>
    <div class="section padding-bottom-30">
      <div class="title">项目经验</div>
      <div class="subtitle">
        <div class="name margin-right-48">能源公司民事九分案例</div>
        <div class="time">2016.04-2020.06</div>
      </div>
      <div class="tag">律师</div>
      <div class="describe">
        中兴能源是国家高新技术企业，在绿色云计算、太阳能光伏、生物质能源、节能减排、农业项目、股权投资等领域具有竞争优势并广受社会褒奖和尊重。
        针对项目特点为客户提供相符合的网站整体风格以及展示方式。
      </div>
      <div class="link">
        <span>项目链接：</span>
        <span>www.sdisldfsd.com</span>
      </div>
    </div>
    <div class="section padding-bottom-34">
      <div class="title">语言能力</div>
      <div class="info blod">
        <div class="item">英语</div>
        <div class="item">读写能力</div>
      </div>
    </div>
    <div class="section padding-bottom-34">
      <div class="title">资格证书</div>
      <div class="subtitle">
        <div class="name margin-right-20">律师资格证</div>
        <div class="time">2016.04</div>
      </div>
    </div>
    <div class="section padding-bottom-40">
      <div class="title">其他信息</div>
      <div class="describe" style="margin-top: 25px">
        架构技术从诞生起经过了怎样的发展曲线？把控项目架构应从哪些地方入手？个人如何提高架构综合能力？且听三位专家的本次分享。
      </div>
    </div>
    <div class="section">
      <div class="title">附件</div>
      <div class="download">
        <div>个人相关文档</div>
        <img src="@as/personal/download_2.png" alt="" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.detail {
  position: relative;
  padding: 40px 44px;
  margin: 0 auto;
  width: 1200px;
  background: #ffffff;
  .float_container {
    position: absolute;
    left: -50px;
    top: 85px;
    .float_item {
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #a3c6cc;
      box-shadow: 0px 0px 4px 0px rgba(3, 27, 78, 0.2);
      border-radius: 50%;
    }
  }
  .section {
    &:not(:last-child) {
      border-bottom: 1px solid #dedede;
    }
    &.flex-jcbt {
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #5c6466;
    }
    .info {
      margin-top: 34px;
      display: flex;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #666666;
      &.blod {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        .item {
          &::after {
            width: 2px;
            background: #333333;
          }
        }
      }
      .item {
        position: relative;
        padding-right: 18px;
        &:not(:first-child) {
          padding-left: 18px;
        }
        &:not(:last-child) {
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 1px;
            height: 16px;
            background: #666666;
          }
        }
      }
    }
    .phone {
      margin-top: 18px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #666666;
    }
    .title {
      margin-top: 38px;
      font-size: 20px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #5c6466;
    }
    .subtitle {
      margin-top: 40px;
      margin-bottom: 24px;
      display: flex;
      .name {
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #333333;
      }
      .tag {
        margin: 0 73px 0 67px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 300;
        color: #333333;
      }
      .time {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 300;
        color: #33333380;
      }
    }
    .row {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #666666;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    .tag {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #333333;
    }
    .describe {
      margin-top: 33px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #666666;
      line-height: 32px;
    }
    .link {
      margin-top: 28px;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #999999;
      span {
        &:first-child {
          color: #333333;
        }
      }
    }
    .download {
      margin-top: 25px;
      display: flex;
      align-items: center;

      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #1c9ab1;
      cursor: pointer;
      .icon {
        margin-left: 30px;
        width: 20px;
        height: 18px;
      }
    }
    .right {
      width: 94px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .margin-right-20 {
    margin-right: 20px;
  }
  .margin-right-90 {
    margin-right: 90px;
  }
  .margin-right-48 {
    margin-right: 48px;
  }
  .padding-bottom-65 {
    padding-bottom: 65px;
  }
  .padding-bottom-26 {
    padding-bottom: 26px;
  }
  .padding-bottom-40 {
    padding-bottom: 40px;
  }
  .padding-bottom-34 {
    padding-bottom: 34px;
  }
  .padding-bottom-30 {
    padding-bottom: 30px;
  }
}
</style>